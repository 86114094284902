import { useEffect } from 'react';
import { useRevalidator } from 'react-router';

/**
 * This hook is used to revalidate the page when a dependency changes.
 * It is useful when you need to revalidate the page after a mutation.
 *
 * @param dependency - The dependency to revalidate the page on.
 * Eg - For dashboard page we want to update the breadcrumbs after title is updated
 * Hence we pass the title as the dependency.
 * Use this when you need to revalidate the page loader
 */

export function usePageRevalidator({ dependency }: { dependency: unknown }) {
  const revalidator = useRevalidator();

  useEffect(() => {
    void revalidator.revalidate();
  }, [dependency, revalidator]);
}
